import Vue from 'vue';
import { API_WMAIL_URL } from '@/config';
export default {
  mail_send(query, data) {
    return Vue.http.post(API_WMAIL_URL + 'v1/mail/send' + query, data);
  },
  get_user_access_token(data) {
    return Vue.http.post(API_WMAIL_URL + 'v1/oauth/token', data);
  }
};

