import WmailService from './wmail';

class WmailApi {

  static get_user_access_token = function(creds) {
    return new Promise((resolve, reject) => {
      // console.log('get_user_access_token', creds)
      let data = {
        'grant_type': 'password',
        'username': creds.username,
        'password': creds.password
      };
      WmailService.get_user_access_token(data)
        .then(resp => {
          if (resp.data.status_code === '2000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

  static mail_send = function(mail_wdmr) {
    return new Promise((resolve, reject) => {
      // console.log(Layers);
      // let query = '?type=' + user_type;
      let query = '';
      let data = {
        'mail': mail_wdmr
      };
      // console.log('data: ', data);
      WmailService.mail_send(query, data)
        .then(resp => {
          // console.log('save_this_wdmr_to_layer_by_wdmr_id_list: ', resp.data);
          if (resp.data.status_code === '2000') {
            resolve(resp.data);
          } else {
            reject(resp.data);
          }
        });
    });
  }

}

export { WmailApi };

