import moment from "moment";
class DateFunctions {
  static day_dif = function(date_1, date_2) { // 1 eski 2 yeni tarih.Daha sonra ikiden biri çıkarıyoruz.
    if (date_2 === 'today') {
      date_2 = new Date();
      date_2.setHours(0, 0, 0, 0);
    } else {
      date_2 = new Date(date_2);
    }
    date_1 = new Date(date_1);
    let date_1_Msec = date_1.getTime();
    let date_2_Msec = date_2.getTime();
    let msecPerDay = 86400000;
    return Math.round((date_2_Msec / msecPerDay) - (date_1_Msec / msecPerDay));
  };

  static datetime_dif = function(date_1, date_2) { // 1 eski 2 yeni tarih.Daha sonra ikiden biri çıkarıyoruz.
    date_1 = new Date(date_1);
    date_2 = new Date(date_2);
    // let date_1_Msec = date_1.getTime();
    // let date_2_Msec = date_2.getTime();
    return date_2 - date_1;
  };

  // static DateTimeFormat = function(date) {};

  static get_this_month_begin_and_end_days = function() {
    var d = new Date();
    var month = d.getMonth();
    month = (parseInt(month) + 1).toString();
    if (month.length < 2) {
      month = '0' + month
    }
    var day = d.getDate();
    var year = d.getFullYear();
    var first_day = year + '-' + month + '-01'
    var last_day_date = new Date(year, month, 0);
    // console.log(last_day.toString()); // last day in the month...
    var last_date_day = last_day_date.getDate()
    if (last_date_day.length < 2) {
      last_date_day = '0' + last_date_day
    }
    var last_day = year + '-' + month + '-' + last_date_day
    return [first_day, last_day];
  };

  static get_today_begin_end_dates = function() {
    var d = new Date();
    var month = d.getMonth();
    month = (parseInt(month) + 1).toString();
    if (month.length < 2) {
      month = '0' + month
    }
    var day = d.getDate();
    if (day.length < 2) {
      day = '0' + day
    }
    var year = d.getFullYear();

    var first_day = year + '-' + month + '-' + day + 'T00:00:00'
    var last_day = year + '-' + month + '-' + day + 'T23:59:59'

    return [first_day, last_day];
  };

  static get_week_begin_end_dates = function() {
    var d_start = new Date();
    var this_day = d_start.getDay();
    // this_day => 0: sunday, 1: monday, 2: tuesday, ...
    d_start.setDate(d_start.getDate() - this_day);
    var month = d_start.getMonth();
    month = (parseInt(month) + 1).toString();
    if (month.length < 2) {
      month = '0' + month
    }
    var day = d_start.getDate();
    if (day.length < 2) {
      day = '0' + day
    }
    var year = d_start.getFullYear();
    var first_day = year + '-' + month + '-' + day + 'T00:00:00'

    var d_end = new Date();
    var this_day = d_end.getDay();
    // console.log('d_end => this_day : ', this_day);
    // this_day => 0: sunday, 1: monday, 2: tuesday, ...
    d_end.setDate(d_end.getDate() + (7 - this_day));
    var month = d_end.getMonth();
    month = (parseInt(month) + 1).toString();
    if (month.length < 2) {
      month = '0' + month
    }
    var day = d_end.getDate();
    if (day.length < 2) {
      day = '0' + day
    }
    var year = d_end.getFullYear();
    var last_day = year + '-' + month + '-' + day + 'T23:59:59'

    return [first_day, last_day];
  };

  static formatDate = function(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  static date_dif_today = function(dt) {
    let date = new Date(dt);
    let dateMsec = date.getTime();
    let today = new Date();
    today.setHours(8, 0, 0, 0);
    let todayMsec = today.getTime();
    let msecPerDay = 86400000;
    let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
    return day_dif;
  };

  static DateFormat = function(date, lang = 'tr') {
    if (date) {
      let months = {
        '01': { 'translation': { 'en': 'january', 'tr': 'ocak' } },
        '02': { 'translation': { 'en': 'february', 'tr': 'şubat' } },
        '03': { 'translation': { 'en': 'march', 'tr': 'mart' } },
        '04': { 'translation': { 'en': 'april', 'tr': 'nisan' } },
        '05': { 'translation': { 'en': 'may', 'tr': 'mayıs' } },
        '06': { 'translation': { 'en': 'june', 'tr': 'haziran' } },
        '07': { 'translation': { 'en': 'july', 'tr': 'temmuz' } },
        '08': { 'translation': { 'en': 'august', 'tr': 'ağustos' } },
        '09': { 'translation': { 'en': 'september', 'tr': 'eylül' } },
        '10': { 'translation': { 'en': 'october', 'tr': 'ekim' } },
        '11': { 'translation': { 'en': 'november', 'tr': 'kasım' } },
        '12': { 'translation': { 'en': 'december', 'tr': 'aralık' } }
      };
      let x = moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      let y = x.split('/');
      return y[0] + ' ' + months[y[1]]['translation'][lang] + ' ' + y[2];
    } else {
      return '';
    }
  };
};

export { DateFunctions };

